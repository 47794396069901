import React from 'react'
import { NavLink } from 'react-router-dom'

export default function Nav() {
  return (
    <div className="flex text-sm text-light">
      <div className="px-4 py-4"><NavLink to="/">Home</NavLink></div>
      <div className="px-4 py-4"><NavLink to="/about">About</NavLink></div>
      <div className="px-4 py-4"><NavLink to={{pathname: "https://mindmeld.donnie.in"}} target="_blank">Blog</NavLink></div>
      <div className="px-4 py-4"><NavLink to="/contact">Contact</NavLink></div>
    </div>
  )
}
