import React from 'react'
import {
  Route,
  BrowserRouter as Router,
} from 'react-router-dom'
import About from './pages/about'
import Contact from './pages/contact'
import Home from './pages/home'
import Resume from './pages/resume'

export default function App() {
  return (
    <Router>
      <Route exact component={Home} path="/" />
      <Route component={About} path="/about" />
      <Route component={Contact} path="/contact" />
      <Route component={Resume} path="/resume" />
    </Router>
  )
}
